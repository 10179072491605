import React from "react"
import { graphql } from "gatsby"
import BlogCategory from "./blog-category"

const Page = ({ data, pageContext }) => {
  return (
    <BlogCategory data={data} pageContext={pageContext} />
  )
}

export default Page

export const pageQuery = graphql`
  query($termId: Int!, $skip: Int!, $limit: Int!) {
    posts: allWordpressPost(
      limit: $limit,
      skip: $skip,
      sort: {order: DESC, fields: date_gmt},
      filter: {post_tag_ids: {in: [$termId]}, status: {eq: "publish"}}
    ) {
      nodes {
        ...PostItem
      }
    }

    term: wordpressTag(term_id: {eq: $termId}) {
      term_id
      taxonomy
      slug
      name
      count
      yoast_title
      yoast_json_ld
      yoast_meta {
        content
        name
        property
      }
    }

    mainPage: wordpressMainPage(name: {eq: "blog-e-receitas"}) {
      ...MainPageFull
    }
  }
`
