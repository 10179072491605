import React from "react"
import { StaticQuery, graphql } from "gatsby"
import WidgetAuthors from "./widget-authors"

const BlogWidgetAuthors = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressAuthor(
            sort: {order: ASC, fields: name},
            filter: {count: {post: {ne: 0}}, caps: {author: {eq: true}}}
          ) {
            nodes {
              ...AuthorFull
            }
          }
          allWordpressPost(filter: {status: {eq: "publish"}}) {
            group(field: author___id) {
              totalCount
              fieldValue
            }
          }
        }
      `}
      render={({allWordpressAuthor, allWordpressPost}) => {
        return <WidgetAuthors
          type="post"
          authors={allWordpressAuthor.nodes} groupCounts={allWordpressPost.group} />
      }}
    />
  )
}

export default BlogWidgetAuthors
