import React from "react"
import { Link } from "gatsby"

const Pagination = ({ currentPage, numPages, pathPrefix }) => {
  const prev = currentPage - 1
  const next = currentPage + 1
  const hasPrev = (prev > 0)
  const hasNext = (next <= numPages)
  const prevLink = prev === 1 ? pathPrefix : `${pathPrefix}/${prev}/`
  const nextLink = `${pathPrefix}/${next}/`
  const currentLink = `${pathPrefix}/${currentPage}/`
  const lastLink = `${pathPrefix}/${numPages}/`

  return (
    <div className="pagination-component is-padding-horizontal-4-touch">
      <nav className="pagination" role="navigation" aria-label="pagination">
        {hasPrev && <Link to={prevLink} className="pagination-previous">{`<< Anterior`}</Link>}

        <ul className="pagination-list is-hidden-mobile">
          {currentPage > 2 && <li><Link to={pathPrefix} className="pagination-link" aria-label="Goto page 1">1</Link></li>}
          {currentPage > 3 && <li><span className="pagination-ellipsis">&hellip;</span></li>}
          {hasPrev && <li><Link to={prevLink} className="pagination-link" aria-label={`Goto page ${prev}`}>{prev}</Link></li>}

          <li><Link to={currentLink} className="pagination-link is-current" aria-label={`Page ${currentPage}`} aria-current="page">{currentPage}</Link></li>

          {hasNext && <li><Link to={nextLink} className="pagination-link" aria-label={`Goto page ${next}`}>{next}</Link></li>}
          {currentPage < (numPages - 2) && <li><span className="pagination-ellipsis">&hellip;</span></li>}
          {currentPage < (numPages - 1) && lastLink && <li><Link to={lastLink} className="pagination-link" aria-label={`Goto page ${numPages}`}>{numPages}</Link></li>}
        </ul>

        {hasNext && <Link to={nextLink} className="pagination-next">{`Próxima >>`}</Link>}
      </nav>
    </div>
  )
}

export default Pagination
