import React from "react"
import { get } from "lodash"
import { Link } from "gatsby"
import LazyLoad from "react-lazy-load"
import Router from "../domain/router"
import Thumbnail from "../domain/thumbnail"
import moment from "../domain/moment"

const WidgetRecentPosts = ({ posts }) => {
  if (!posts || posts.length === 0) return null

  return (
    <div className="widget-recent-posts-component is-margin-bottom-8">
      <div className="is-margin-bottom-4">
        <h1 className="is-size-5 has-text-weight-bold">
          Post recentes
        </h1>
      </div>

      {posts.map(post => (<PostItem key={post.post_id} post={post} />))}
    </div>
  )
}

const PostItem = ({ post }) => {
  const postPath = Router.blogPostPath(post);
  const primary_category = 'widget-recent-posts category-' + get(post, "primary_category.slug")

  return (
    <article className="media post-item">
      <figure className="media-left">
        <LazyLoad>
          <Link to={postPath} className={`image-box with-fix-width is-round-2 has-background-black ${primary_category}`} style={{ width: '70px', height: '50px' }}>
            <img src={Thumbnail.postMiniImage(post)} alt={post.title} />
          </Link>
        </LazyLoad>
      </figure>
      <div className="media-content">
        <Link to={postPath} className={`has-text-weight-bold has-text-dark link-hover ${primary_category}`}>
          {post.title}
        </Link>
        <div className="hast-text-grey is-margin-top-2">
          {moment(post.date).format("DD MMM YYYY")}
        </div>
      </div>
    </article>
  )
}

export default WidgetRecentPosts
