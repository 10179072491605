import React from "react"
import WidgetListWithCount from "./widget-list-with-count"
import Router from "../domain/router"

const WidgetAuthors = ({ type, authors, groupCounts }) => {
  const items = authors.map(author => {
    const group = groupCounts.find(g => g.fieldValue === author.id)
    const count = group ? group.totalCount : 0

    return {
      title: author.name,
      link: Router.blogAuthorPath(type, author.slug),
      count
    }
  })

  return (
    <WidgetListWithCount
      title="Autores"
      linkClassName="has-text-orange"
      items={items}
    />
  )
}

export default WidgetAuthors
