import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import Search from "./search"
import Router from "../domain/router"

class BlogSearch extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.state = { categories: [], tags: [], posts: [] }
  }

  componentDidMount() {
    axios.get(`/metadata/blog.json`)
      .then((response) => {
        this.setState({
          categories: response.data.categories,
          tags: response.data.tags,
          posts: response.data.posts,
        })
      })
  }

  handleChange(selectedOption) {
    console.log('selectedOption', selectedOption)
    navigate(selectedOption.value)
  }

  render() {
    const { categories, tags, posts } = this.state
    const type = "post"

    const options = []
    categories.forEach(term => options.push({ value: Router.blogCategoryPath(type, term.slug), label: term.name }))
    tags.forEach(term => options.push({ value: Router.blogTagPath(type, term.slug), label: term.name }))
    posts.forEach(post => options.push({ value: Router.blogPostPath(post), label: post.title }))

    return (
      <div className="blog-search-component is-margin-bottom-8">
        <Search
          placeholder="Buscar notícia…"
          options={options}
          onChange={this.handleChange} />
      </div>
    )
  }
}

export default BlogSearch
