import React from "react"
import { StaticQuery, graphql } from "gatsby"
import WidgetCategories from "./widget-categories"

const BlogWidgetCategories = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressCategory(sort: {order: ASC, fields: name}, filter: {count: {ne: 0}}) {
            nodes {
              id
              count
              slug
              name
              term_id
            }
          }
          allWordpressPost(filter: {status: {eq: "publish"}}) {
            group(field: category___id) {
              totalCount
              fieldValue
            }
          }
        }
      `}
      render={({allWordpressCategory, allWordpressPost}) => {
        return <WidgetCategories
          linkClassName="has-text-blue"
          type="post"
          categories={allWordpressCategory.nodes} groupCounts={allWordpressPost.group} />
      }}
    />
  )
}

export default BlogWidgetCategories
