import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBanner from "../components/page-banner"
import BlogContent from "../components/blog-content"
import BlogTabs from "../components/blog-tabs"
import BlogGrid from "../components/blog-grid"
import HomeContact from "../components/home-contact"
import Router from "../domain/router"

const Page = ({ data, pageContext }) => {
  const { author, term, mainPage } = data
  const posts = data.posts.nodes
  const parent_link = Router.blogPath("post")
  const active = term && term.slug
  
  let title = term && term.name
  if (author) title = author.name

  return (
    <Layout>
      <SEO term={term} author={author} />
      <PageBanner title={title} parentPost={mainPage} parent_link={parent_link} />
      <BlogTabs type="post" active={active} />
      <BlogContent>
        <BlogGrid posts={posts} pageContext={pageContext} />
      </BlogContent>
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($termId: Int!, $skip: Int!, $limit: Int!) {
    posts: allWordpressPost(
      limit: $limit,
      skip: $skip,
      sort: {order: DESC, fields: date_gmt},
      filter: {category_ids: {in: [$termId]}, status: {eq: "publish"}}
    ) {
      nodes {
        ...PostItem
      }
    }

    term: wordpressCategory(term_id: {eq: $termId}) {
      term_id
      taxonomy
      slug
      name
      count
      yoast_title
      yoast_json_ld
      yoast_meta {
        content
        name
        property
      }
    }

    mainPage: wordpressMainPage(name: {eq: "blog-e-receitas"}) {
      ...MainPageFull
    }
  }
`
