import React from "react"
import AsyncSelect from "react-select/async"
import * as JsSearch from 'js-search'

const IndicatorsContainer = () => (
  <div className="indicator">
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search"
      className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
  </div>
)

const filterColors = (inputValue, searcher) => {
  const result = searcher.search(inputValue)
  return result
}

const loadOptions = (options) => (inputValue, callback) => {
  const docs = options.map((option, index) => {
    return Object.assign(option, { index })
  })

  const searcher = new JsSearch.Search('index')
  searcher.addIndex('label')
  searcher.addIndex('description')
  searcher.addDocuments(docs)

  callback(filterColors(inputValue, searcher));
}

const Search = ({ placeholder, options, onChange }) => {
  const customStyles = {
    control: (provided, _state) => { return { ...provided, borderRadius: '25px', borderColor: '#333', padding: 6 } },
    menu: (provided, _state) => { return { ...provided, zIndex: 900 } },
  }

  const isLoading = options.length === 0

  return (
    <div className={`search-component ${isLoading ? 'component-is-loading' : null}`}>
      <AsyncSelect
        isLoading={isLoading}
        placeholder={placeholder}
        loadOptions={loadOptions(options)}
        styles={customStyles}
        components={{ IndicatorsContainer: IndicatorsContainer }}
        onChange={onChange} />
    </div>
  )
}

export default Search
