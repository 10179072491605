import React from "react"
import { get } from "lodash"
import { StaticQuery, graphql } from "gatsby"
import LazyLoad from "react-lazy-load"
import Thumbnail from "../domain/thumbnail"

const WidgetEbooksContainer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressEbook(filter: {status: {eq: "publish"}}, sort: {order: ASC, fields: menu_order}) {
            nodes {
              post_id
              title
              acf {
                link
                file {
                  url
                }
              }
              thumbnail {
                sizes {
                  thumbnail
                }
              }
            }
          }
        }
      `}
      render={data => {
        return <WidgetEbooks
          posts={data.allWordpressEbook.nodes} />
      }}
    />
  )
}

const WidgetEbooks = ({ posts }) => {
  if (!posts || posts.length === 0) return null

  return (
    <div className="widget-recent-posts-component is-margin-bottom-8">
      <div className="is-margin-bottom-4">
        <h1 className="is-size-5 has-text-weight-bold">
          E-books
        </h1>
      </div>

      {posts.map(post => <EbookItem key={post.post_id} post={post} />)}
    </div>
  )
}

const EbookItem = ({ post }) => {
  let link = get(post, "acf.link") || get(post, "acf.file.url")

  return (
    <article className="media post-item">
      <figure className="media-left">
        <LazyLoad>
          <a href={link} target="_blank" rel="noopener noreferrer" className="image-box with-fix-width is-round-2 has-background-black" style={{ width: '70px', height: '50px' }}>
            <img src={Thumbnail.postMiniImage(post)} alt={post.title} />
          </a>
        </LazyLoad>
      </figure>
      <div className="media-content">
        <div className="">
          <a href={link} target="_blank" rel="noopener noreferrer" className="has-text-weight-bold has-text-dark link-hover">
            {post.title}
          </a>
        </div>
        <div className="">
          <a href={link} target="_blank" rel="noopener noreferrer" className="has-text-grey-light">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download"
              className="svg-inline--fa fa-download fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
            </svg> Baixe já
          </a>
        </div>
      </div>
    </article>
  )
}

export default WidgetEbooksContainer
