import React from "react"
import { Link } from "gatsby"

const Item = ({ linkClassName, title, link, count }) => (
  <div className="category-item border-bottom-1">
    <div className="columns is-mobile">
      <div className="column">
        <div>
          <Link to={link} className={`is-padding-vertical-2 is-family-primary has-text-weight-bold is-block ${linkClassName}`}>
            {title}
          </Link>
        </div>
      </div>
      <div className="column is-narrow">
        <div className="is-padding-vertical-2">
          <span className="count">
            {count}
          </span>
        </div>
      </div>
    </div>
  </div>
)

const WidgetListWithCount = ({ title, items, linkClassName }) => {
  if (!items || items.length === 0) return null

  return (
    <div className="widget-list-with-count-component is-margin-bottom-8">
      <div className="is-margin-bottom-4">
        <h1 className="is-size-5 has-text-weight-bold">
          {title}
        </h1>
      </div>

      {items.map((item, index) => <Item key={index} linkClassName={linkClassName} {...item} />)}
    </div>
  )
}

export default WidgetListWithCount
