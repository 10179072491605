import React from "react"
import { Form, Field } from "react-final-form"
import ReCAPTCHA from "react-google-recaptcha"
import emailValidator from "email-validator"
import axios from "axios"
import Translations from "../domain/translations"
import { showSuccess, showError } from "../domain/notifications"

const emailValid = value => (emailValidator.validate(value) ? undefined : Translations.form.errors.required)

const resetForm = (values, form) => {
  setTimeout(() => {
    Object.keys(values).forEach(key => {
      form.resetFieldState(key)
    })
    form.reset()
  })
}

const onSubmit = async (values, form) => {
  try {
    const { email } = values
    const recaptcha_token = await recaptchaRef.current.execute()

    await axios.post(`${process.env.WORDPRESS_BASE_URL}/superbom/v1/subscribers`, {
      recaptcha_token,
      email,
    })

    resetForm(values, form)
    showSuccess(Translations.notifications.success_email, Translations.notifications.thanks)
  } catch (e) {
    showError(Translations.notifications.error_email, Translations.notifications.try_later)
  }
}

const recaptchaRef = React.createRef()

const WidgetEbooks = () => {
  return (
    <div className="widget-newsletter-component is-margin-bottom-8">
      <div className="is-margin-bottom-4">
        <h1 className="is-size-5 has-text-weight-bold has-text-white">
          Newsletter
        </h1>
      </div>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, invalid }) => (
          <form
            onSubmit={handleSubmit}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.GOOGLE_RECAPTCHA_CLIENT_KEY}
            />

            <div className="columns is-gapless is-mobile">
              <div className="column">

                <Field
                  name="email"
                  validate={emailValid}
                  type="text"
                  render={({ input }) => (
                    <input {...input} className="input is-round-top-left-4 is-round-bottom-left-4 is-round-top-right-0 is-round-bottom-right-0" type="text" placeholder="Digite seu e-mail..." />
                  )}
                />

              </div>
              <div className="column is-narrow">

                <button
                  id="widget-newsletter-button"
                  disabled={submitting || invalid}
                  className={`button is-primary is-round-bottom-right-4 is-round-top-right-4 is-round-top-left-0 is-round-bottom-left-0 ${submitting && 'is-loading'}`}>
                  <span className="icon is-small">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="paper-plane"
                      className="svg-inline--fa fa-paper-plane fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path></svg>
                  </span>
                </button>

              </div>
            </div>

          </form>
        )}
      />

    </div>
  )
}

export default WidgetEbooks
