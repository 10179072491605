import React from "react"
import  { get } from "lodash"
import { Link } from "gatsby"
import Router from "../domain/router"
import Thumbnail from "../domain/thumbnail"
import moment from "../domain/moment"
import Pagination from "./pagination"
import LazyLoad from "react-lazy-load"

const PostItem = ({ post }) => {
  const link = Router.blogPostPath(post)
  const primary_category = 'blog-grid category-' + get(post, "primary_category.slug")

  return (
    <div className="column is-half">
      <div className="post-item is-margin-bottom-10">
        <div className="is-margin-bottom-4">
          <Link to={link} className={primary_category}>
            <LazyLoad>
            <figure className="image is-2by1 image-box with-fix-width is-round-2 has-background-black">
              <img src={Thumbnail.postGridImage(post)} alt={post.title}  />
            </figure>
            </LazyLoad>
          </Link>
        </div>
        <div className="is-margin-bottom-2">
          <div className="columns is-vcentered is-mobile is-variable is-1">
            {post.primary_category &&
              <div className="column is-narrow">
                <Link to={Router.blogCategoryPath(post.type, post.primary_category.slug)} className={`has-text-weight-bold has-text-blue ${primary_category}`}>
                  {post.primary_category.name}
                </Link>
              </div>}
            <div className="column is-narrow">&bull;</div>
            <div className="column is-narrow">
              <span>
                {moment(post.date).format("DD MMM YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="is-margin-bottom-5">
          <Link to={link} className={`link-hover has-text-black is-size-5 has-text-weight-bold ${primary_category}`}>
            {post.title}
          </Link>
        </div>
        <div className="is-margin-bottom-7">
          <p className="is-size-6">
            {post.excerpt}
          </p>
        </div>
        <div>
          <Link to={link} className={`button is-rounded is-orange ${primary_category}`}>
            <strong>Ler mais</strong>
          </Link>
        </div>
      </div>
    </div>
  )
}

const BlogGrid = ({ posts, pageContext }) => {
  return (
    <section className="blog-grid-component">
      <div className="columns is-multiline">
        {posts.map(post => (<PostItem key={post.post_id} post={post} />))}
      </div>

      <Pagination {...pageContext} />
    </section>
  )
}

export default BlogGrid
