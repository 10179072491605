import React from "react"
import { Link } from "gatsby"
import Router from "../domain/router"

const WidgetTags = ({ type, tags, itemClassname }) => {
  if (!tags || tags.length === 0) return null

  return (
    <div className="widget-recent-posts-component is-margin-bottom-8">
      <div className="is-margin-bottom-4">
        <h1 className="is-size-3 has-text-weight-bold">
          Tags
        </h1>
      </div>

      <div>
        {tags.map(tag => (<TagItem key={tag.term_id} type={type} tag={tag} itemClassname={itemClassname} />))}
      </div>
    </div>
  )
}

const TagItem = ({ type, tag, itemClassname }) => (
  <Link to={Router.blogTagPath(type, tag.slug)} className={`widget-tags button is-outlined ${itemClassname} is-small is-margin-right-2 is-margin-bottom-2`} style={{ borderRadius: 8 }}>
    <span className="has-text-weight-bold has-text-dark">
      {tag.name}
    </span>
  </Link>
)

export default WidgetTags
