import React from "react"
import WidgetListWithCount from "./widget-list-with-count"
import Router from "../domain/router"

const WidgetCategories = ({ type, categories, groupCounts, linkClassName }) => {
  const items = categories.map(term => {
    const group = groupCounts.find(g => g.fieldValue === term.id)
    const count = group ? group.totalCount : 0

    return {
      title: term.name,
      link: Router.blogCategoryPath(type, term.slug),
      count
    }
  })

  return (
    <WidgetListWithCount
      title="Categorias"
      linkClassName={linkClassName}
      items={items}
    />
  )
}

export default WidgetCategories
