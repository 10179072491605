import React from "react"
import BlogSearch from "./blog-search"
import BlogWidgetCategories from "./blog-widget-categories"
import BlogWidgetTags from "./blog-widget-tags"
import BlogWidgetRecentPosts from "./blog-widget-recent-posts"
import BlogWidgetAuthors from "./blog-widget-authors"
import WidgetEbooks from "./widget-ebooks"
import WidgetNewsletter from "./widget-newsletter"

const BlogContent = ({ children }) => {
  return (
    <div className="blog-content-component is-padding-horizontal-4-touch is-margin-vertical-10">
      <div className="container">
        <div className="columns">
          <div className="column is-8-desktop is-8-tablet">
            <div className="is-margin-bottom-10-touch">
              {children}
            </div>
          </div>
          <div className="column is-1-desktop is-hidden-touch"></div>
          <div className="column is-3-desktop is-4-tablet">
            <BlogSearch />
            <WidgetEbooks />
            <WidgetNewsletter />
            <BlogWidgetCategories />
            <BlogWidgetAuthors />
            <BlogWidgetRecentPosts />
            <BlogWidgetTags />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogContent
